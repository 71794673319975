.ProductPrice{
  width:100%;
  height:auto;
  margin-top:10px;
}

.ProductPrice .price{
  background-color:black;
  color:white;
  height:35px;
  width: 20%;
  display:inline-block;
  text-align:right;
  padding:5px 8px 5px 8px;
}
.ProductPrice .extrasInfo{
  color:white;
  height:35px;
  padding:5px 8px 5px 8px;
  text-align:center;
}

.ProductPrice .price span {
  color: white;
  font-size: 1.15rem;
}
.ProductPrice .extrasInfo span {
  font-size: 0.925rem;
  color: black;
}

.ProductPrice .newPrice .newPriceIndicator{
  position:absolute;
  top:0px;
  left:0px;
  height:auto;
  width:175px;
}

.ProductPrice .newPrice{
  position:relative;
  height:35px;
  overflow:hidden;
  padding: 5px 8px 5px 8px;
}

.ProductPrice .newPrice span{
  position:absolute;
  right:10px;
  z-index:10;
  font-size: 1.15rem;
  color: black;
}

.ProductPrice .priceLabel{
  text-align:left;
  padding-top:7px;
}
.ProductPrice .priceLabel span{
  color: white;
  font-size: 0.85rem;
  text-align:left;
}
