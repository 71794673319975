.QuestionsSection{
  position:absolute;
  top:50%;
  left:50%;
  width:100%;
  transform:translate(-50%, -50%);
}

.QuestionsSection .iconAskContainer{
  width:20%;
  max-width:162px;
  min-width:120px;
  margin:0 auto 20px auto;
}

.QuestionsSection .dialogContainer{
  max-width: 450px;
  padding:10px;
  margin:0 auto;
  width:100%;
  text-align: center;
}

.QuestionsSection .dialogContainer .pet.petRegularSize{
  width:75%;
  max-width:225px;
  height:320px;
  margin:20px auto 0px auto;
  position:relative;
}

.QuestionsSection .dialogContainer .pet.petRegularSize .petImg{
  width:100%;
  position: absolute;
  left:0px;
  max-width:100%;
  height:auto;
}

.QuestionsSection .dialogLineContainer {
  max-width: 320px;
  margin: 10px auto 0px auto;
}
.QuestionsSection .dialogLineContainer .dialogLine{
  width:100%;
  height:auto;
}

.pictureGlass.napkin{
  /* height: 100vh !important; */
  min-height:750px;
  max-height:100vh !important;
}


@media (max-width:650px) {
  .QuestionsSection .dialogContainer .pet.petRegularSize{
    max-width:185px;
    height:295px;
  }
}

@media (max-width:450px) {
  .QuestionsSection .dialogContainer .pet.petRegularSize{
    max-width:165px;
    height:268px;
  }

}

@media (max-width:360px) {
  .QuestionsSection .dialogContainer .pet.petRegularSize{
    max-width:155px;
    height:253px;
  }
}
