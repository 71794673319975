.inputGap{
  position:relative;
  width:100%;
  height:30px;
}

.inputGap .input{
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height:30px;
  border:none;
  outline:none;
  &.center{ text-align:center; }
  &.right{ text-align:right; }
  &.left{ text-align:left; }
}

.inputGap .label {
  position:absolute;
  width:100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.center{ text-align:center; }
  &.right{ text-align:right; }
  &.left{ text-align:left; }
}
.inputGap .label{outline:none;}
.inputGap .label.enable{display:block;}
.inputGap .label.disable{display:none;}

.inputGap .input.uppercase {
  text-transform:uppercase
}




/* THEMES */
/* BlackTheme */
.inputGap .label.BlackTheme.enable{color:#636464;}
.inputGap .input.typeSolid.BlackTheme.noFocused{
  background-color: #fbfbfb;
  color:#636464;
}
.inputGap .input.typeTransparent.BlackTheme.noFocused{
  background-color: transparent;
  color:#636464;
}
.inputGap .input.typeSolid.BlackTheme.focussed{
  background-color: #636464;
  color:#fff;
}
.inputGap .input.typeTransparent.BlackTheme.focussed{
  background-color: transparent;
  color:#636464;
}
/* WhiteTheme */
.inputGap .label.WhiteTheme.enable{color:white;}
.inputGap .input.typeSolid.WhiteTheme.noFocused{
  background-color: #636464;
  color:#fff;
}
.inputGap .input.typeSolid.WhiteTheme.focussed{
  background-color: #fbfbfb;
  color:#636464;
}
