.LoginForm{
  position:relative;
  width:100%;
  height:auto;
  .inputGap{
    border-bottom:1px solid #3f3f3f;
    height:31px;
    width:100%;
    max-width:256px;
    margin: 0 auto 20px auto;
  }
  .buttonLogin {
    width: 50%;
    // background-color: #3f3f3f;
    // padding:5px;
    // width:100%;
    // margin:20px auto 20px auto;
    // max-width:100px;
    // cursor:pointer;
    // outline:none;
    // p{
    //   color:white;
    //   text-align:center;
    //   font-family:mimItalic;
    // }
  }
}
