.IWantItSection.textContainer{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:80%;
  max-width: 450px;
  min-width:300px;
  margin: 0 auto;
}

.IWantItSection.textContainer .readBlock{
  position:relative;
  margin:0 auto;
  max-width:500px;
  min-width:300px;
}

.IWantItSection.textContainer p{
  color:white;
  text-align: justify;
  margin-top: 20px;
  font-size:1rem;
}


.IWantItSection .devicesIwant{
  width:50%;
  min-width:250px;
  max-width: 320px;
  margin:30px auto 0px auto;
  height:auto;
}

.IWantItSection .devicesIwant img{
  width:100%;
  height: auto;
}

.IWantItSection .iwantIcon {
  width: 35%;
  min-width: 120px;
  max-width:175px;
  margin:0 auto;
  height:auto;
}

.IWantItSection .iwantIcon img{
  width:100%;
  height:auto;
}

.IWantItSection .iwkmoreContainer {
  width: 155px;
  margin:60px auto;
  height:auto;
}

.IWantItSection .iwkmoreContainer img{
  width:100%;
  height:auto;
}


.petSectionWI {
  position:relative;
  width: 100%;
  height: 150px;
  overflow:hidden;
  margin:0 auto;
}

.petSectionAbsoluteWI{
  position: absolute;
  width:100%;
  max-width:400px;
  height: 279px;
  bottom:0px;
  left:50%;
  transform:translate(-50%, 18%);
}

.petSectionAbsoluteWI .pet.petRegularSize{
  width:100%;
  max-width:100%;
}

.petSectionAbsoluteWI .pet.petRegularSize .petImg{
  width:100%;
  max-width:100%;
  height:auto;
}

.pictureGlass.loquiero{
  height: 100vh !important;
  max-height:850px;
  min-height:800px;
}

.pictureGlass.loquiero .parallax-banner-layer-0{
  background-position: center;
  background-size:cover;
  background-image: url(//mim.cool/assets/web/chicos.jpg)
}

.pictureGlass.napkin .parallax-banner-layer-0{
  background-position: center;
  background-size:cover;
  background-image: url(//mim.cool/assets/web/bgnapkin.jpg);
}



@media (max-width:750px) {
  .pictureGlass.loquiero .parallax-banner-layer-0{
    background-image: url(//mim.cool/assets/web/chicos-mov.jpg)
  }

  .pictureGlass.loquiero{
    height: 100vh !important;
    min-height:780px;
    max-height:100vh !important;
  }

  .IWantItSection .iwkmoreContainer {
    width:100%;
    max-width: 140px;
  }

  .petSectionWI {
    height: 0px;
  }

  .petSectionAbsoluteWI{
    width:60%;
    max-width:350px;
    min-width: 300px;
    height: 279px;
    bottom:0px;
    left:50%;
    transform:translate(-50%, 32%);
  }
}

@media (max-width:500px) {
  .petSectionWI {
    height: 100px;
  }
}
