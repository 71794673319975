.pqsections{
  padding:10px;
  padding-top:5px;
  padding-bottom:35px;

}


.pqsections .selectWrapper{
  margin-top:25px;
}

.pqsections .blackLine{
  margin-top:20px;
}
