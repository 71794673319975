
.FloatButtons.hidden {
  display: none;
}

.FloatButton {
  position:fixed !important;
  top: 2%;
  min-height:72px;
  width:72px !important;
  z-index: 40;
  outline: none;
  cursor:pointer;
}

.FloatButton img{
  position:absolute;
  top:0%;
  left:0%;
}

.FloatButton.Menu{
  left:2%;
  /*background-color:pink;*/
}

.FloatButton.Menu.activated .btn2{
  transform-origin: center;
  animation: fbmenu 0.5s linear 0s forwards;
}

.FloatButton.Menu .btn2{
  transform-origin: center;
  animation: fbmenuReverse 0.5s linear 0s forwards;
}

@keyframes fbmenuReverse{
	from {
    transform: rotate(0deg);
    opacity:0;
    display:block;
  }
 	to {
    opacity:1;
    display:block;
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes fbmenuReverse{
	from {
    opacity:0;
    display:block;
    -webkit-transform: rotate(0deg);
  }
 	to {
    opacity:1;
    display:block;
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes fbmenu{
	from {
    transform: rotate(0deg);
    opacity:1;
    display:block;
  }
  99%{
    display:block;
  }
 	to {
    display:none;
    opacity:0;
    transform: rotate(360deg);
  }
}
@-webkit-keyframes fbmenu{
  from {
    transform: rotate(0deg);
    opacity:1;
    display:block;
  }
  99%{
    display:block;
  }
 	to {
    display:none;
    opacity:0;
    transform: rotate(360deg);
  }
}



.FloatButton.Login{
  right:2%;
  /* background-color:blue;*/
}

.FloatButton.Login img{
  width:100%;
  height:auto;
}

@media (max-width:750px) {
  .FloatButton{
    width:60px;
    min-height:60px;
  }
}

@media (max-width:650px) {
  .FloatButton{
    width:55px;
    min-height:55px;
  }
}
