.FooterHome .container {
  padding:10px 0px 10px 0px;
  display:flex;
  flex-wrap: wrap;
  width:100%;
  max-width: 1000px;
  align-items: stretch;
  justify-content: space-between;
}
.FooterHome .container.dark {
  display:block;
  padding:10px;
  max-width: 100%;
  background-color: #3f3f3f;
}

.FooterHome .container.dark .subContainer{
  max-width: 1000px;
  padding: 10px 0px;
  margin:0 auto;
}

.FooterHome .logoFotCont {
  height: auto;
  width:35%;
  min-width:130px;
  max-width: 185px;
}

.FooterHome .mimRights .mimRights_text{
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.FooterHome .mimRights .blackLine{
  margin-top: 10px;
  width:60%;
  max-width: 66px;
}

.FooterHome .logoFooter img {
  width:100%;
  height:auto;
}

.FooterHome .socialContainers{
  display:inline-flex;
  align-items: center;
  vertical-align: top;
}

.FooterHome .mimRights,
.FooterHome .socialContainers{
  padding-top:15px;
}

.FooterHome .socialContainers a{
  color: #3f3f3f;
  text-decoration: none;
  font-family: mimItalic;
}

.FooterHome .socialContainers img{
  height:auto;
  margin-left:10px;
  width:35px;
}

.FooterHome .container.dark .language {
  width: 100px;
  height: auto;
  float:left;
}

.FooterHome .container.dark .language span {
  color: white;
  cursor: pointer;
}

.FooterHome .container.dark .design {
  width: 215px;
  height: auto;
  float:right;
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.FooterHome .container.dark .design .label {
  display:inline-block;
  color:white;
  font-family:mimItalic;
}

.FooterHome .container.dark .design .vic {
  display:inline-block;
  height:auto;
  width:127px;
}

@media (max-width:767px) {
  .FooterHome .container {
    padding:10px;
  }
  .FooterHome .mimRights,
  .FooterHome .socialSection,
  .FooterHome .logoFotCont {
    width: 100%;
    max-width:100%;
    min-width:100%;
    text-align: center;
  }
  .FooterHome .logoFotCont{
    order: 3;
  }
  .FooterHome .socialSection{
    order: 1;
  }
  .FooterHome .mimRights {
    order: 2;
  }
  .FooterHome .mimRights,
  .FooterHome .socialContainers,
  .FooterHome .logoFotCont {
    padding:10px;
  }
  
  .FooterHome .mimRights .blackLine{
    margin-bottom:0px;
  }

  .FooterHome .logoFotCont .logoFooter{
    width:100%;
    min-width:130px;
    max-width: 185px;
    margin:0 auto;
    display:inline-block;
  }

}
