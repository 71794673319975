.EmptyRTO{
  padding-top: 59px;
}

.EmptyRTO .content{
  max-width:360px;
  margin:25px auto;
}

.EmptyRTO .content .pet.petRegularSize{
  margin:5px auto 20px auto;
  width: 185px;
  height: 136px;
}

.EmptyRTO .content .pet.petRegularSize .petImg{
  width:100%;
  height:auto;
}

.EmptyRTO .content .dialogLine{
  max-width:280px;
  margin:0 auto;
  width:100%;
  padding-top:10px;
  height:auto;
}
