.headerMenu{
  position:fixed;
  top:0px;
  left:0px;
  width:100%;
  z-index:30;
  height: 41px;
  border-bottom:2px solid #fff;
}
.headerContainer {
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  height: 100%;
}

.headerContainer.regularDegraded{
  background: rgba(86,87,90,1);
  background: -moz-linear-gradient(top, rgba(86,87,90,1) 0%, rgba(83,84,86,1) 40%, rgba(71,71,73,1) 44%, rgba(67,65,66,1) 48%, rgba(63,62,63,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(86,87,90,1)), color-stop(47%, rgba(83,84,86,1)), color-stop(50%, rgba(71,71,73,1)), color-stop(53%, rgba(67,65,66,1)), color-stop(100%, rgba(63,62,63,1)));
  background: -webkit-linear-gradient(top, rgba(86,87,90,1) 0%, rgba(83,84,86,1) 40%, rgba(71,71,73,1) 44%, rgba(67,65,66,1) 48%, rgba(63,62,63,1) 100%);
  background: -o-linear-gradient(top, rgba(86,87,90,1) 0%, rgba(83,84,86,1) 40%, rgba(71,71,73,1) 44%, rgba(67,65,66,1) 48%, rgba(63,62,63,1) 100%);
  background: -ms-linear-gradient(top, rgba(86,87,90,1) 0%, rgba(83,84,86,1) 40%, rgba(71,71,73,1) 44%, rgba(67,65,66,1) 48%, rgba(63,62,63,1) 100%);
  background: linear-gradient(to bottom, rgba(86,87,90,1) 0%, rgba(83,84,86,1) 40%, rgba(71,71,73,1) 44%, rgba(67,65,66,1) 48%, rgba(63,62,63,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#56575a', endColorstr='#3f3e3f', GradientType=0 );
}


.headerHeight {
    width:100%;
    height: 41px;
}

.headerContainer .textTabContainer{
  position: absolute;
  height:100%;
  top: 0px;
  right: 0px;
}

.headerContainer .headerTab{
  display: inline-block;
}

.headerContainer .headerTab.mimTab {
  position: relative;
  left:0px;
  top:0px;
  height:100%;
  width:30%;
  padding:0px 5px;
  max-width: 120px;
}
.headerContainer .active {
  background: rgba(235,235,235,1);
  background: -moz-linear-gradient(top, rgba(235,235,235,1) 0%, rgba(236,236,236,1) 40%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(235,235,235,1)), color-stop(40%, rgba(236,236,236,1)), color-stop(51%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
  background: -webkit-linear-gradient(top, rgba(235,235,235,1) 0%, rgba(236,236,236,1) 40%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
  background: -o-linear-gradient(top, rgba(235,235,235,1) 0%, rgba(236,236,236,1) 40%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
  background: -ms-linear-gradient(top, rgba(235,235,235,1) 0%, rgba(236,236,236,1) 40%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
  background: linear-gradient(to bottom, rgba(235,235,235,1) 0%, rgba(236,236,236,1) 40%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebebeb', endColorstr='#ffffff', GradientType=0 );
}

.headerContainer .headerTab.mimTab .icon-logo-cabecera {
  position: absolute;
  top: 50%;
  left: 50%;
  height:21px;
  width:auto;
  transform: translate(-50%, -50%);
}
.headerContainer .headerTab.textTab {
  position:relative;
  height:100%;
  padding: 10px 8px 0px 8px;
  border-left: 1px solid #61615f;
  cursor:pointer;
  outline:none;
}


.headerContainer .headerTab.textTab .magnifyingGlass{
  position:relative;
  top:0px;
  width:15px;
  height:auto;
}



.headerContainer .headerTab.textTab .option {
  position:relative;
  top:3px;
  font-size:1.05rem;
  text-align: center;
  text-transform: uppercase;
  font-family: 'MimItalic';
  color: #FFFFFF;
}
.headerContainer .headerTab.textTab.active .option {
  color: #494949;
}

.headerContainer .headerIndicator{
  position:absolute;
  width:100%;
  height:15px;
  top:0px;
  right:0px;
}

.headerContainer .headerIndicator .numberIndicator{
  position:absolute;
  top:-1.55px;
  left:0px;
  width:100%;
  color:#000;
  font-size: 0.80rem;
  text-align:right;
  padding-right:8px;
  padding-top:2px;
  padding-bottom:2px;
  line-height:11px;
  height:11px;
}

.headerContainer .headerIndicator .headerBackGround{
  position:absolute;
  top:0px;
  left:0px;
  width:100%;
  overflow:hidden;
  height:14px;

}
.headerContainer .headerIndicator .headerBackGround img,object {
  position:absolute;
  top:-2.5px;
  left:0px;
  width:auto;
  height:109%;
}

.beforeConnection .logoLayer {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}
.beforeConnection .icon-logo-connect{
  height:21px;
  width:auto;
}


.headerContainer .headerIndicator span{
  color:#fff
}

@media screen and (max-width:385px) {
  .headerContainer .headerTab.mimTab{max-width:90px;}
  .headerContainer .headerTab.textTab{ padding:0px 5px}
  .headerContainer .headerTab.textTab .option{position:relative; top:15px;}
  .headerContainer .headerTab.textTab .magnifyingGlass{margin-right:2px;margin-left:2px;}
}
