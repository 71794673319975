@import './slick-theme.css';
@import './slick.css';

body {
  margin:0;
  padding:0px;
  min-height: 100dvh;
  // background-color: black;
}

*{
  margin:0px;padding:0px;
  box-sizing:border-box;
  overscroll-behavior: contain;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }
  html{-ms-overflow-style:scrollbar;-webkit-tap-highlight-color:transparent; scroll-behavior: smooth;}
  
  /* MÓDULO 0: FUENTES RESPONSIVE */
  html, body {font-size: 20px;font-family: 'MimRegular', sans-serif;}
  p, li, ul, span, .htmlText p, .htmlText li, .htmlText ul, .htmlText span {font-size: 1rem;line-height: 130%;color: #3f3f3f;font-family: 'MimRegular', sans-serif;}
  li,ul{margin-bottom:10px;}
  h1, .htmlText h1, h1 span {font-size: 1.55rem;line-height: 1.35rem;color: #3f3f3f;font-family: 'MimRegular', sans-serif;font-weight: normal !important;}
  h2, .htmlText h2, h2 span {font-size: 1.50rem;line-height: 1.50rem;color: #3f3f3f;font-family: 'MimRegular', sans-serif;font-weight: normal !important;}
  h3, .htmlText h3, h3 span {font-size: 1.45rem;line-height: 1.45rem;color: #3f3f3f;font-family: 'MimRegular', sans-serif;font-weight: normal !important;}
  h4, .htmlText h4, h4 span {font-size: 1.40rem;line-height: 1.40rem;color: #3f3f3f;font-family: 'MimRegular', sans-serif;font-weight: normal !important;}
  h5, .htmlText h5, h5 span {font-size: 1.35rem;line-height: 1.35rem;color: #3f3f3f;font-family: 'MimRegular', sans-serif;font-weight: normal !important;}
  h6, .htmlText h6, h6 span {font-size: 1.30rem;line-height: 1.30rem;color: #3f3f3f;font-family: 'MimRegular', sans-serif;font-weight: normal !important;
  }
  
  .justifyText p{text-align: justify;}
  
  @media screen and (max-width:2980px) { html, body {font-size: 16px;} }
  @media screen and (max-width:980px) { html, body {font-size: 16px;} }
  @media screen and (max-width:374px) { html, body {font-size: 16px;} }
  @media screen and (max-width:330px) { html, body {font-size: 15px;} }
  
  .animated {
    animation-delay:0s;
    -webkit-animation-delay: 0s;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    }
  .animated.infinite {-webkit-animation-iteration-count: infinite; animation-iteration-count: infinite;}
  /* MÓDULO 1: SISTEMA GRID BOOTSTRAP 4*/ /* quito el punto de ruptura en 768px AHORA SOMOS RESPONSIVE DE VERDAD */
  .container{width:100%;margin:0 auto;overflow:hidden}
  .row{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:10px;margin-left:10px}
  .row.margin0{margin:0px;}
  .col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,
  .col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,
  .col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,
  .col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,
  .col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9{position:relative;width:100%;min-height:1px}
  /*defininición de los col*/
  
  .col-xs{-webkit-flex-basis:0;-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;max-width:100%}
  .col-xs-auto{-webkit-box-flex:0;-webkit-flex:0 0 auto;-ms-flex:0 0 auto;flex:0 0 auto;width:auto}
  .col-xs-1{-webkit-box-flex:0;-webkit-flex:0 0 8.333333%;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
  .col-xs-2{-webkit-box-flex:0;-webkit-flex:0 0 16.666667%;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
  .col-xs-3{-webkit-box-flex:0;-webkit-flex:0 0 25%;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
  .col-xs-4{-webkit-box-flex:0;-webkit-flex:0 0 33.333333%;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
  .col-xs-5{-webkit-box-flex:0;-webkit-flex:0 0 41.666667%;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
  .col-xs-6{-webkit-box-flex:0;-webkit-flex:0 0 50%;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
  .col-xs-7{-webkit-box-flex:0;-webkit-flex:0 0 58.333333%;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
  .col-xs-8{-webkit-box-flex:0;-webkit-flex:0 0 66.666667%;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
  .col-xs-9{-webkit-box-flex:0;-webkit-flex:0 0 75%;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
  .col-xs-10{-webkit-box-flex:0;-webkit-flex:0 0 83.333333%;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
  .col-xs-11{-webkit-box-flex:0;-webkit-flex:0 0 91.666667%;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
  .col-xs-12{-webkit-box-flex:0;-webkit-flex:0 0 100%;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
  
  /*media queris sm (768px)*/
  @media (min-width:768px){
    .col-sm{-webkit-flex-basis:0;-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;max-width:100%}
    .col-sm-auto{-webkit-box-flex:0;-webkit-flex:0 0 auto;-ms-flex:0 0 auto;flex:0 0 auto;width:auto}
    .col-sm-1{-webkit-box-flex:0;-webkit-flex:0 0 8.333333%;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
    .col-sm-2{-webkit-box-flex:0;-webkit-flex:0 0 16.666667%;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
    .col-sm-3{-webkit-box-flex:0;-webkit-flex:0 0 25%;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
    .col-sm-4{-webkit-box-flex:0;-webkit-flex:0 0 33.333333%;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
    .col-sm-5{-webkit-box-flex:0;-webkit-flex:0 0 41.666667%;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
    .col-sm-6{-webkit-box-flex:0;-webkit-flex:0 0 50%;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
    .col-sm-7{-webkit-box-flex:0;-webkit-flex:0 0 58.333333%;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
    .col-sm-8{-webkit-box-flex:0;-webkit-flex:0 0 66.666667%;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
    .col-sm-9{-webkit-box-flex:0;-webkit-flex:0 0 75%;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
    .col-sm-10{-webkit-box-flex:0;-webkit-flex:0 0 83.333333%;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
    .col-sm-11{-webkit-box-flex:0;-webkit-flex:0 0 91.666667%;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
    .col-sm-12{-webkit-box-flex:0;-webkit-flex:0 0 100%;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
  }
  
  div[class^="col-"]{padding-left:10px; padding-right: 10px}
  div[class^="col-"].padding0{padding:0px;}
  .linkOverBox{position:absolute;top:0px;width:100%;left:0px;bottom:0px;height:100%;outline:none;}
  .contentCentered {
    position:absolute;
    top:50%;
    width:100%;
    left:50%;
    transform:translate(-50%, -50%);
  }
  


@font-face {
  font-family: 'MimBold';
  src: url('/fonts/josefinsans-bold.woff2') format('woff2'),
       url('/fonts/josefinsans-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MimItalic';
  src: url('/fonts/josefinsans-italic.woff2') format('woff2'),
       url('/fonts/josefinsans-italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MimBoldItalic';
  src: url('/fonts/josefinsans-semibolditalic.woff2') format('woff2'),
       url('/fonts/josefinsans-semibolditalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;  
} 

@font-face {
  font-family: 'MimRegular';
  src: url('/fonts/josefinsans-regular.woff2') format('woff2'),
       url('/fonts/josefinsans-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;  
}

@font-face {
  font-family: 'Mimthin';
  src: url('/fonts/josefinsans-thin.woff2') format('woff2'),
       url('/fonts/josefinsans-thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;  
}


.mimBold{
  font-family: 'MimBold'  !important;
  letter-spacing: 1px;
  /* text-shadow: 1px 0 0 currentColor; */
}

.mimItalic {
  font-family: 'MimItalic'  !important;
}

.mimBoldItalic {
  font-family: 'MimBoldItalic'  !important;
}

.mimUpperCase{
  text-transform: uppercase;
}