.AdviseGPS{
  width:100%;
  margin:0 auto;
}

.AdviseGPS .topInfo {
  max-width:320px;
  width:100%;
  margin:0 auto;
}

.AdviseGPS .topInfo .gpsPin{
  margin-top:20px;
  width:65px;
  height:auto;
}

.AdviseGPS .topInfo .TitleValance{
  margin-top:10px;
}

.AdviseGPS .topInfo .pet.petRegularSize{
  margin:20px auto 20px auto;
  width: 125px;
  height: 175px;
}

.AdviseGPS .topInfo .pet.petRegularSize .petImg{
  width:100%;
  height:auto;
}

.AdviseGPS .GoldSection{
  padding:10px 0px;
}

.AdviseGPS .GoldSection h3,
.AdviseGPS .GoldSection p{
  margin-bottom:10px;
}

.AdviseGPS .GoldSection .YellowSection{
  padding:5px;
}
.AdviseGPS .GoldSection .YellowSection p{
  margin-bottom:0px;
}

.AdviseGPS .GoldSection .arrowDGray{
  margin:10px auto;
  width:15px;
  height:auto;
}

.AdviseGPS .GoldSection .askingPermission{
  width:276px;
  height:auto;
  margin:0 auto;
}

.AdviseGPS .GoldSection .askingPermission span {
  color:#fff;
}