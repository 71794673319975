.container.navigationBar {
  position:fixed;
  top:39px;
  height: 39px;
  -webkit-box-shadow: 0px 15px 30px -10px rgba(0,0,0,3);
  -moz-box-shadow: 0px 15px 30px -10px rgba(0,0,0,0.3);
  box-shadow: 0px 15px 30px -10px rgba(0,0,0,0.3);
  background-color:white;
  margin-bottom: 20px;
  z-index:30;
}

.navigationBar .navigationLink{
  position: relative;
  text-transform: uppercase;
  height: 39px;
}

.navigationBar .navigationLink .backBap{
  position: absolute;
  width:20px;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.navigationBar .navigationLink .backBap.en{
  right: 25px;
}
.navigationBar .navigationLink .backBap.es{
  right: 15px;
}

.navigationBar .navigationLink .backBap .back {
  height:18px;
  width:auto;
}

.navigationBar .navigationLink span{
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size:0.85rem;
}

.navigationBar .navigationName{
  text-align: right;
  position: relative;
  height: 39px;
}

.navigationBar .navigationName span{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  text-transform:capitalize;
}
