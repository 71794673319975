.TextDisplayer{
  position:relative;
  overflow:hidden;
}
.TextDisplayer div{
  position:absolute;
  top:50%;
  left:0%;
  transform:translate(-0%,-50%);
  white-space: nowrap;
}

.moving{
  -webkit-animation: 6s movingLoop infinite linear;
  animation:6s movingLoop infinite linear;
}

@keyframes movingLoop {
 0% {left:0%}
 50%{left:-50%}
 to {left:0%}
}
@-webkit-keyframes movingLoop {
  0% {left:0%}
  50%{left:-50%}
  to {left:0%}
}