.ProductItem {
  position:relative;
  margin-bottom:15px;
  /*ffcb00*/
}
.ProductItem .nameRow{
  position:relative;
  height:25px;
  padding:0px;
  background-color:#444444;
}

.ProductItem .nameRow .script{
  position:relative;
  display:inline-block;
  vertical-align: top;
  width:15px;
  height:100%;
  margin-right:10px;
}
.ProductItem .nameRow .name{
  display:inline-block;
  text-align:left;
  color:white;
  line-height:25px;
}

.ProductItem .nameRow .plus{
  color:#ffcb00;
  float:right;
  margin-right:10px;
  line-height:20px;
  display:inline-block;
}


.ProductItem .descriptionRow{
  padding:15px;
  overflow:hidden;
  width: 100%;
  height: 60px;
  overflow: hidden;
}



.ProductItem .priceBySize{
  margin: 10px 0px 10px 0px;
}

.ProductItem .priceBySize p {
  text-align: center;
}
