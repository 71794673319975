.CustomizeProduct{
  position:relative;
  background-color:white;
  width: 100vw;
  height: 100vh;
  padding-top:10px;
  padding-bottom:10px;
  .container {
    max-width: 450px;
  }
}

.CustomizeProduct .slick-list{
  overflow:visible;
  z-index: 98;
}

.CustomizeProduct .TitleValance .titleText{
  line-height:15px;
  padding-bottom:5px;
}

.CustomizeProduct .chooseText{
  text-align:center;
  text-transform: uppercase;
}

.CustomizeProduct .productName{
  text-align:center;
  text-transform: uppercase;
  margin-bottom:15px;
}

.CustomizeProduct .stomp{
  outline: none;
}

.numberContainer p {
  vertical-align: middle;
}

.CustomizeProduct .questionsGap {
  padding-top:20px;
  min-height:400px;
}

.CustomizeProduct .containerButtons{
  max-width:360px;
  margin:0 auto;
}

.CustomizeProduct .containerButtons .yesButton span{
  color:white;
}

.CustomizeProduct .ProductPrice{
  margin:0px;
  margin-top:8px;
}
