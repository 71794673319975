.UnitsPriceInput{
  width:100%;
  height:auto;
  margin-top:10px;
}

.UnitsPriceInput .price{
  background-color:black;
  color:white;
  height:35px;
  max-width: 110px;
  width: 20%;
  display:inline-block;
  text-align:right;
  padding:5px 8px 5px 8px;
}

.UnitsPriceInput .units{
  width:30px;
}
.UnitsPriceInput .times,
.UnitsPriceInput .units,
.UnitsPriceInput .price{
  height:35px;
}

.UnitsPriceInput .times,
.UnitsPriceInput .units,
.UnitsPriceInput .newPrice {
  position:relative;
  display:inline-block;
  padding:5px;
}


.UnitsPriceInput .times span,
.UnitsPriceInput .units span,
.UnitsPriceInput .newPrice span{
  font-size:1.15rem;
  color:black;
}

.UnitsPriceInput .price span{
  color:white;
  font-size:1.15rem;
}

.UnitsPriceInput .newPrice .newPriceIndicator{
  position:absolute;
  top:0px;
  left:0px;
  height:100%;
  width:auto;
}

.UnitsPriceInput .newPrice{
  float:right;
  height:35px;
  width:105px;
  padding: 5px 8px 5px 8px;
}

.UnitsPriceInput .newPrice span{
  position:absolute;
  right:10px;
  z-index:10
}

.UnitsPriceInput .roundedButton{
  width:35px;
  height:auto;
  margin:0px 5px 0px 0px;
  vertical-align: bottom;
  display:inline-block;
}
