.MiMDescription{
  padding: 10px;
}
.MiMDescription .mimLogo{
  width: 305px;
  height: auto;
  margin-top:60px;
}
.MiMDescription .mainTitle{
  margin-top:40px;
}
.MiMDescription .mainTitleValance{
  width:276px;
  height:auto;
  margin:15px auto 30px auto;
}
.MiMDescription .descriptionContainer {
  display:flex;
  margin: 0 auto;
  max-width:1000px;
  width:100%;
  vertical-align: top;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
}
.MiMDescription .descriptionContainer .blackLine{
  margin-top:20px;
  margin-bottom:30px;
}
.MiMDescription .descriptionContainer .description {
  width:30%;
  text-align: justify;
}
.MiMDescription .blackLine.mov{
  display: none;
}


@media (max-width:767px) {
  .MiMDescription .mimLogo{
    width:260px;
  }
  .MiMDescription .descriptionContainer .description {
    width:100%;
    margin-bottom: 10px;
  }
  .MiMDescription .descriptionContainer.pc {
    display:none;
  }
  .MiMDescription .blackLine.mov{
    display: block;
    margin-top:15px;
    margin-bottom:10px;
  }
  .MiMDescription .pet.petRegularSize{
    width:270px;
    height:185px;
  }
}
