/* .selectWrapper starting stylings ------------------------------*/
.selectWrapper {
	position: relative;
  width: 100%;
	overflow:visible;
	padding-top:5px;
}
.selectWrapper div {
	overflow:visible;
	border:none;
	font-family: mimItalic;
	font-size: 1.10rem;
}

.selectWrapper .react-select__multi-value{
	margin:2.5px 5px 2.5px 0px;
  border-radius: 0px;
	display:inline-block;
	width:auto;
	height:25px;
	background-position: center;
	background: -webkit-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
	background: -o-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
	background: -ms-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
	background: -moz-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
	background: linear-gradient(to right, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
}

.selectWrapper .react-select__value-container .react-select__multi-value__remove,
.selectWrapper .react-select__value-container .react-select__multi-value__label{
	position:relative;
	display:inline;
}
.selectWrapper .react-select__value-container .react-select__multi-value__label{
	padding-right:10px;
}
.selectWrapper .react-select__value-container .react-select__multi-value__remove svg{
	position:relative;
	top:3px;
}

.selectWrapper .react-select__indicators {
  display:none;
}
.selectWrapper .select-style select:focus {
    outline: none;
}

.selectWrapper .react-select__control {
	position: relative;
	min-height:35px;
	background-color: transparent;
  width: 100%;
	border-radius: 0;
	border: none;
}

.selectWrapper .react-select__placeholder{
	position:absolute;
  margin-left: 5px;
	color:#4e4f4f;
	text-align:center;
  width: 100%;
	font-size:1.10rem;
}
/* Remove focus */
.selectWrapper .react-select__control--is-focused {
	outline: none;
  box-shadow: none;
}

	/* Use custom arrow */
.selectWrapper .react-select__control {
	appearance: none;
  padding: 0px 5px;
}

.selectWrapper .noValue:after{
	position: absolute;
	top: 14px;
	right: 10px;
	/* Styling the down arrow */
	width: 0;
	height: 0;
	padding: 0;
	content: '';
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid #4e4f4f;
	pointer-events: none;
}
.selectWrapper .hasValue:after {
	position: absolute;
	top: 14px;
	right: 10px;
	/* Styling the down arrow */
	width: 0;
	height: 0;
	padding: 0;
	content: '';
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid rgb(250, 208, 66);
	pointer-events: none;
}

/* active state */
.selectWrapper .select-title,.selectWrapper .select-title-click {
  display: block;
	color: #4e4f4f;
	top: -18px;
	transition: 0.2s ease all;
  position: absolute;
	text-align: center;
	font-family: 'MimItalic';
	width:100%;
	font-size:1.05rem;
	text-transform:uppercase;
}

/* BOTTOM BARS ================================= */
.selectWrapper .select-bar {
	position: relative;
	display: block;
  max-width:340px;
  width:100%;
  margin:0 auto;
}

.selectWrapper .select-bar:before, .select-bar:after {
	content: '';
	height: 1px;
	width: 0;
	bottom: 1px;
	position: absolute;
	background: #019BCE;
	transition: 0.2s ease all;
}

.selectWrapper .select-bar:before {
	left: 50%;
}

.selectWrapper .select-bar:after {
	right: 50%;
}

/* active state */
.selectWrapper .react-select__control:focus ~ .select-bar:before, .react-select__control:focus ~ .select-bar:after {
	width: 50%;
}

/* HIGHLIGHTER ================================== */
.selectWrapper .select-highlight {
	position: absolute;
	height: 60%;
	width: 100px;
	top: 25%;
	left: 0;
	pointer-events: none;
	opacity: 0.5;
}
/*MENU*/
.selectWrapper .react-select-container{
	/* background-color:#424242;*/
	font-family: mimBoldItalic;
}
.selectWrapper .react-select-container.noValue{
	background-color:#dadada;
}
.selectWrapper .react-select-container.hasValue{
	background-color:#424242;
}

.selectWrapper .react-select__menu {
	position: absolute;
  top: 0px;
	color:#fff;
  background-color:#424242;
  border-radius: 0px;
	min-height:35px;
	margin:0px;
  /* box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1); */
  /* margin-bottom: 8px; */
  width: 100%;
  z-index: 11;
  box-sizing: border-box;
}

.selectWrapper .react-select__menu-notice{
	background-color:#424242;
	color:#fff;
	padding: 9px 5px 5px 0px;
}

.selectWrapper .react-select__menu-notice--no-options{
}

.selectWrapper .react-select__menu-list{
 padding-bottom: 0px;
 padding-top: 0px;
}
.selectWrapper .react-select__single-value {
 position: absolute;
 color: rgb(250, 208, 66);
 top:50%;
 left:50%;
 transform:translate(-50%, -50%);
}
.selectWrapper .react-select__value-container {
 padding: 2px 5px 2px 5px;
 display:block;
 overflow: inherit;
 text-align:center;
}

.selectWrapper .react-select__option--is-selected {
	color:#3f3f3f;
	background-position: center;
 	background: -webkit-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
 	background: -o-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
 	background: -ms-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
 	background: -moz-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
 	background: linear-gradient(to right, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
}

.selectWrapper .react-select__option{
	background-color:#424242;
	padding: 10px 19px;
}
.selectWrapper .react-select__option:hover{
	background-color:#424242;
}


.selectWrapper .react-select__control:hover {
  /* border-color: #4e4f4f; */
}

.selectWrapper .variousOptionsPerLine .react-select__menu-list{
	text-align:center;
}
.selectWrapper .variousOptionsPerLine .react-select__option{
	display: inline-block;
	max-width: 40px;
	width: 30%;
	padding: 7px 5px;
}