.imageButton{
  border:none;
  display:block;
  width:100%;
  height:auto;
  background-color:transparent;
  position: relative;
  margin:0 auto;
  outline:none;
  cursor:pointer;
}

.imageButtonText{
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.imageButton .buttonImage, .imageButton .buttonImageHover{
  height: 100%;
  width: 100%;
}

.imageButton .buttonImageHover{
  display: none;
}

.imageButton .buttonImage{
  display: block;
  cursor:pointer
}
