.productInfo{
  position:relative;
  width:100%;
  height:auto;
}

.productInfo .gapImg{
  width:100%;
  background-color:black;
  background-size:cover;
  background-position:center;
  height:225px;
}

.productInfo .blackLine{
  margin-top:15px;
}

.productInfo .textContent,
.productInfo .ingredientsContent {
  max-width:360px;
  height:auto;
  padding:10px;
  margin:0 auto;
}

.productInfo .textContent {
  text-align: justify;
}

.productInfo .ingredientsContent {
  text-align:center;
}

.productInfo .ingredientsContent .allergenIconGap{
  width:75px;
  margin:10px;
  height:auto;
  vertical-align: top;
  display:inline-block;
}
.productInfo .ingredientsContent .allergenIconGap span {
  display:block;
}
.productInfo .ingredientsContent .allergenIconGap img{
  height:75px;
}
