.homeSlider{
  width:100%;
  height: auto;
}

.homeSlider .slick-dots{
  bottom:10px;
  z-index:9;
}
.homeSlider .slick-dots li button:before{
  font-size:8px;
}

.homeSlider .slide {
  position:relative;
  outline:none;
  background-position:center top;
  background-size:cover;
  background-repeat: no-repeat;
  max-height:100vh;
  aspect-ratio: 1.51;
  width:100%;
  background-color:black;
}
.homeSlider .slide .info {
  position:relative;
  width:80%;
  max-width:400px;
  min-width: 250px;
  height:100%;
  margin:0 auto;
  display:flex;
  flex-flow:column;
  align-items: stretch;
  justify-content: space-around;
}
.homeSlider .slide .info .circleInfo{
  height:40%;
}

.homeSlider .slide .info .pet.petRegularSize{
  width: 175px;
  margin:0 auto;
  height: 30%;
  max-height:185px;
}

.homeSlider .slide .info .pet.petRegularSize .petImg{
  position:absolute;
  left:50%;
  width:auto;
  height:100%;
  transform: translate(-50%, 0%);
}

