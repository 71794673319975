.HomeMenu{
  width: 100vw;
  // position:fixed;
  // top:0px;
  // left:0px;
  // right:0px;
  // bottom:0px;
  // z-index:10;
  background-image:url(//mim.cool/assets/web/bgMenu.jpg);
  background-color: white;
  .container{
    display:flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
    width:100%;
    max-width:460px;
    margin:0 auto;
    .menuOptions{
      width:100%;
      .mimLogoGap{
        text-align: center;
        margin-bottom:30px;
        .mimLogo{
          width:50%;
          max-width:240px;
        }
      }
      .optionMenu {
        cursor: pointer;
        outline: none;
        // height:45px;
        margin:10px auto 10px auto;
        text-align: center;
        &.option1.es{
          .menuValance.valance{

          }
        }
        .menuValance {
          margin:0 auto;
          img {
            opacity:0;
            width:60px;
            height:auto;
            vertical-align: top;
          }
        }
      }
    }
    .petGap{
      width:auto;
      height: 30%;
      max-height:225px;
      .pet.petRegularSize{
        height:100%;
        width:auto;
      }
      .pet.petRegularSize .petImg{
        position:absolute;
        left:50%;
        width:auto;
        height:100%;
        transform: translate(-50%, 0%);
      }
    }
  }
}
