.Prompt{
  position:fixed;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  z-index: 99;
  background-color:#525252
}

.Prompt .pet.petRegularSize{
  position:absolute;
  top:50%;
  left:50%;
  width: 150px;
  height: 211px;
  transform:translate(-50%,-50%);
}

.Prompt .pet.petRegularSize .petImg{
  width: 150px;
  height: 211px;
}
