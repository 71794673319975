.TitleValance{
  width:100%;
  max-width:360px;
  margin:0 auto;
  padding-right:10px;
  padding-left:10px;
  display:block;
  overflow:hidden;
}

.TitleValance .valance {
  margin:-5px auto 10px auto;
  width:140px;
  height:auto;
}

.TitleValance .titleText {
  text-align: center;
  display: block;
}

.TitleValance .titleText {
  font-size: 25px;
  vertical-align: bottom;
  line-height: 30px;
}

.TitleValance .icon {
  width: auto;
  height: 52px;
  margin-left: 10px;
}