.priceSelect{
  margin-bottom:10px;
}

.priceSelect .description{
  text-align: center;
  margin-bottom:10px;
}

.priceSelect .selectContainer{
  display:flex;
  justify-content: space-around;
}
.priceSelect .selectContainer .sizeblock{
  min-width:95px;
}

.priceSelect .mealSize{
  padding:10px 5px 10px 5px;
  text-align: center;
  cursor:pointer;
  outline: none;
}
.priceSelect .mealSize.nodefined{
  cursor:default;
}
.priceSelect .mealSize span{
  color:#c4c4c4;
}

.priceSelect .mealSize .price{
  font-size:1.35rem;
  display:inline-block;
}
.priceSelect .mealSize .currency{
  display:inline-block;
  font-size:1.20rem;
}

.priceSelect .mealSize.nodefined span,
.priceSelect .mealSize.selected span{
  color: #4e4f4f;
}

.priceSelect .mealSize.selected{
  background-position: center;
  background: -webkit-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  background: -o-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  background: -ms-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  background: -moz-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  background: linear-gradient(to right, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
}
.priceSelect .mealSize span{
  display:block;
}


.priceSelect .add2Ticket{
  width:276px;
  height:auto;
  margin:10px auto 0px auto;
}

.priceSelect .add2Ticket span {
  color:#fff;
  text-transform: uppercase;
}
