.businessContainer{width:100%;height:auto;}

.businessContainer .container.doubleSpaceContainer{
  margin-top: 25px;
}

.businessContainer .container.singleSpaceContainer{
  margin-top: 15px;
}

.businessContainer .imageButtonText{
  color: #494949;
}

.businessContainer .keypadContainer{
  margin:0 auto;
  width:100%;
  max-width:360px;
  height:auto;
}

.businessContainer .infoContainer .imageButton{
  height: 35px;
}

.businessContainer .eatNDrinkContainer .imageButton .imageButtonText{
  top: 75%;
}

.businessContainer .useContainer .imageButton{
  height: 35px;
}

.businessContainer .languageContainer .imageButton{
  width: 100%;
  height:auto;
}


.businessContainer .businessInfo{
  height:auto;
  max-width:320px;
}
