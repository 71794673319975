.WrongQR{
  position:relative;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  z-index:99;
  background-color:#fff;
}

.WrongQR .sadQR{
  margin-top:20px;
  width:65px;
  height:auto;
}

.WrongQR .blackLine{
  border-bottom:1px solid black;
  width:40px;
  height:1px;
  margin:0 auto;
  margin-bottom:15px;
}

.WrongQR p{margin-bottom:15px;}
.WrongQR h6{margin-bottom:15px;}


.WrongQR .imageButton{
  width:147px;
  height:auto;
  margin:0px auto 15px auto;
}


.WrongQR .pet.petRegularSize{
  padding-left:10px;
  margin:30px auto 0px auto;
  height: 170px;
  width:auto;
}
.WrongQR .pet.petRegularSize .petForReferenceSize{
  height: 180px;
}


.WrongQR .pet.petRegularSize .petImg{
  position:absolute;
  height: 170px;
}

.WrongQR .mimBlackLogo{
  position:absolute;
  width:75px;
  height:auto;
  bottom:0px;
  left:50%;
  transform:translate(-50%,-50%);
}
