.LoginMenu{
  width: 100vw;
  // position:fixed;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  // z-index:10;
  background-image:url(//mim.cool/assets/web/bgMenu.jpg);
  background-color: white;
  .container{
    display:flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
    width:100%;
    max-width:460px;
    margin:0 auto;
    .loginSection{
      width: 100%;
      .button{
        background-color:#3f3f3f;
        color:white;
        max-width:150px;
        margin:0 auto 40px auto;
        padding:5px;
        font-size:0.85rem;
        text-align: center;
      }
      .goPanelButton{
        margin-top:30px;
        font-size:0.80rem;
        text-transform: uppercase;
      }
      .titleGoPanel{
        text-align: center;
        font-size:0.85rem;
        font-family:MimBoldItalic;
        text-transform: uppercase;
      }
      .descriptionGoPanel{
        text-align: center;
        font-size:0.85rem;
        font-family:MimItalic;
        text-transform: uppercase;
      }
      .opSentence{
          text-align: center;
          margin-top: 20px;
          font-size:0.95rem;
      }
    }
    .petGap{
      width:auto;
      height: 30%;
      max-height:225px;
      .pet.petRegularSize{
        height:100%;
        width:auto;
      }
      .pet.petRegularSize .petImg{
        position:absolute;
        left:50%;
        width:auto;
        height:100%;
        transform: translate(-50%, 0%);
      }
      .zContainer{
        position:absolute;
        width:20px;
        height:20px;
        -webkit-animation-name: opacityZ;
        animation-name: opacityZ;
        animation-iteration-count: infinite;

        &.first{
          top:0px;
          left:10px;
          animation-duration: 2s;
          -webkit-animation-duration:2s;
          animation-delay:1s;
          -webkit-animation-delay: 1s;
        }
        &.second{
          top:-24px;
          left:25px;
          animation-duration: 3s;
          -webkit-animation-duration:3s;
          animation-delay:2s;
          -webkit-animation-delay: 2s;
        }
        &.third{
          top:-15px;
          left:45px;
          animation-duration: 4s;
          -webkit-animation-duration:4s;
          animation-delay:3s;
          -webkit-animation-delay: 3s;
        }
        img{
          width:100%;
          height: auto;
        }
      }
    }
  }
}

@-webkit-keyframes opacityZ {
  0% {opacity: 0;}
  50% {opacity:0;}
  100% {opacity: 1;}
  100%{opacity:0;}
}

@keyframes opacityZ {
  0% {opacity: 0;}
  50% {opacity:0;}
  75% {opacity: 1;}
  100%{opacity:0;}
}
