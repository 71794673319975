.imgNLogoContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: no-repeat center bottom;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  height: 220px;
  position: relative;
  background-color:black;
}

.imgNLogoContainer .businessBasicInfoCentered{
  position:absolute;
  top:50%;
  left:50%;
  width:100%;
  min-width:320px;
  padding:10px;
  background-color:rgba(63, 63, 63, 0.5);
  transform:translate(-50%, -50%);
}

.imgNLogoContainer .businessBasicInfoCentered h2 {
  text-align:center;
  color: white;
  height:20px;
  font-size: 20px;
  text-transform: uppercase;
}

.imgNLogoContainer .logo{
  position: absolute;
  z-index: 1;
  max-width: 120px;
  max-height: 120px;
  margin:0 auto;
  display: block;
  width: auto;
  outline: 1px solid white;
  height: auto;
  border-radius: 100%;
}
